<template>
  <v-container fluid full class="overviewContainer mb-10">
    <v-row wrap>
      <v-col cols="12" md="8" class="pa-5">
        <v-row wrap class="reProduct">
          <v-col cols="12">
            <h6>Shipping Information</h6>

            <v-row>
              <v-col cols="12" md="8" v-if="shippingAddress.length >= 1">
                <p>
                  {{ shippingAddress[0].name }}
                  <br />
                  {{ shippingAddress[0].phoneNumber }}
                  <br />
                  {{ shippingAddress[0].address }},
                  {{ shippingAddress[0].city }},
                  {{ shippingAddress[0].country }},
                  {{ shippingAddress[0].zipCode }}
                </p>
              </v-col>
              <v-col cols="12" md="4" align="left" style="color:red;">
                <v-dialog v-model="dialogShippingAddress" width="450">
                  <template v-slot:activator="{ on, attrs }">
                    <span style="color:red;" v-bind="attrs" v-on="on"
                      ><v-icon size="15" color="red">mdi-plus</v-icon> Add New
                      Address</span
                    >
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      Shipping Information
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        color="black"
                        @click="dialogShippingAddress = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-4">
                      <v-row>
                        <v-col cols="10" offset="1">
                          <v-layout>
                            <v-text-field
                              dense
                              outlined
                              label="Full Name"
                              v-model="shippingAddress.name"
                            >
                            </v-text-field>
                          </v-layout>
                          <v-layout>
                            <v-text-field
                              dense
                              outlined
                              label="Phone Number"
                              v-model="shippingAddress.phoneNumber"
                            >
                            </v-text-field>
                          </v-layout>
                          <v-layout>
                            <v-text-field
                              dense
                              outlined
                              label="Address"
                              v-model="shippingAddress.address"
                            >
                            </v-text-field>
                          </v-layout>
                          <v-layout>
                            <v-select
                              :items="country"
                              label="Select Country"
                              v-model="shippingAddress.country"
                              outlined
                              dense
                              item-text="name"
                              @change="getCountryStates"
                            ></v-select>
                          </v-layout>
                          <v-layout>
                            <v-select
                              :items="states"
                              label="Select State"
                              outlined
                              dense
                              item-text="name"
                              v-model="shippingAddress.city"
                            ></v-select>
                          </v-layout>
                          <v-layout>
                            <v-text-field
                              dense
                              outlined
                              label="Zip Code"
                              v-model="shippingAddress.zipCode"
                            >
                            </v-text-field>
                          </v-layout>
                          <v-layout>
                            <v-switch
                              color="#7CBF46"
                              v-model="shippingAddress.isDefault"
                              label="Make Default"
                            ></v-switch>
                          </v-layout>

                          <v-layout color="#BD2121" v-if="error">
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title class="text-danger"
                                    ><v-icon color="#F81515">mdi-cancel</v-icon>
                                    {{ errorMessage }}</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-layout>

                          <v-divider></v-divider>
                          <v-layout>
                            <v-progress-linear
                              color="#7CBF46"
                              v-show="loader"
                              :indeterminate="true"
                            ></v-progress-linear>
                          </v-layout>
                          <v-layout class="mt-3">
                            <v-btn class="buttonStyle" @click="saveAddress">
                              Save Address
                            </v-btn>
                          </v-layout>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row wrap class="reProduct mt-10">
          <v-col cols="12">
            <h6>Payment Method</h6>
            <v-row>
              <v-col cols="12" md="6" v-if="selectedPaymentGateway !== null">
                <v-row>
                  <v-col cols="12" v-if="selectedPaymentGateway == 'paystack'">
                    <v-img
                      height="100%"
                      src="/img/paystack.png"
                      contain
                      @click="selectPaymentGateway('paystack')"
                    />
                  </v-col>

                  <!-- <v-col cols="12" v-if="selectedPaymentGateway == 'paypal'">
                    <v-img height="100%" src="/img/Paypal.png" contain @click="selectPaymentGateway('paypal')" />
                  </v-col>
                  <v-col cols="12" v-if="selectedPaymentGateway == 'stripe'">
                    <v-img height="100%" src="/img/stripe.png" contain @click="selectPaymentGateway('stripe')" />
                  </v-col> -->
                  <v-col cols="12" v-if="selectedPaymentGateway == 'e-wallet'">
                    <v-img height="100%" src="/img/e-wallet.png" contain @click="selectPaymentGateway('e-wallet')" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog v-model="dialogPaymentMethod" width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <span style="color:red;" v-bind="attrs" v-on="on"
                      ><v-icon size="15" color="red">mdi-plus</v-icon> Select
                      other payment methods</span
                    >
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      Payment Methods
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        color="black"
                        @click="dialogPaymentMethod = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-4">
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-img
                            height="100%"
                            src="/img/paystack.png"
                            contain
                            @click="selectPaymentGateway('paystack')"
                          />
                        </v-col>
                        <!-- <v-col cols="12" md="3">
                          <v-img
                            height="100%"
                            src="/img/Paypal.png"
                            contain
                            @click="selectPaymentGateway('paypal')"
                          />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-img
                            height="100%"
                            src="/img/stripe.png"
                            contain
                            @click="selectPaymentGateway('stripe')"
                          />
                        </v-col> -->
                        <v-col cols="12" md="3">
                          <v-img
                            height="100%"
                            src="/img/e-wallet.png"
                            @click="selectPaymentGateway('e-wallet')"
                            contain
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row wrap class="reProduct mt-10">
          <v-col cols="12">
            <h5>Order Review ({{ cartItems.length }} items)</h5>
            <v-divider></v-divider>
            <v-row
              v-for="(product, index) in cartItems"
              :key="index"
              class="mt-2"
            >
              <v-col cols="12" md="3">
                <v-img
                  height="100"
                  :src="product.Product.productImagePath"
                  contain
                />
              </v-col>
              <v-col cols="12" md="9">
                <p>
                  {{ getProductContent(product.Product) }}
                </p>
                <p>
                  <b>Quantity:</b> <span>{{ product.qty }}</span>
                </p>
                <h3 class="mt-4 priceTag">
                  {{ systemSetting.systemCurrency }}
                  {{ product.Product.price * product.qty }}
                </h3>
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
       
      </v-col>

      <!--- Recommendation Product Layout ---->
      <v-col cols="12" md="4" class="pa-5">
        <v-row class="reProduct">
          <h6>Order Summary</h6>
          <!-- <v-select
            :items="PromoPackages"
            label="Promos"
            prepend-inner-icon="mdi-ticket-percent"
          ></v-select>
          <v-layout wrap>
            <v-col cols="12" md="8">
              <v-text-field solo label="Promo Code"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn outlined color="#5B964F" small class="mt-3">
                Apply
              </v-btn>
            </v-col>
          </v-layout> -->
          <v-row wrap class="reProduct mt-10">
            <v-col cols="12">
              <p style="font-size:14px;">
                <b>Select a Logistic company to deliver your order.</b> 
                <v-select
                :items="activeLogisticsCompanyData"
                label="Logistics Companies Available"
                prepend-inner-icon="mdi-truck"
                item-text="name"
                item-value="logisticsCompanyId"
                @change="fetchLogisticComapany($event)"
              ></v-select>
              </p>
            </v-col>
          </v-row>
          <v-layout wrap>
            <v-col cols="12" md="6">
              <h6>Orders Total</h6>
            </v-col>
            <v-col cols="12" md="5">
              <h6>{{ systemSetting.systemCurrency }}{{ total.toFixed(2)}}</h6>
            </v-col>
          </v-layout>
          <v-layout wrap>
            <v-col cols="12" md="6">
              <h6>Logistics Fee</h6>
            </v-col>
            <v-col cols="12" md="5">
              <h6>{{ systemSetting.systemCurrency }}{{ selectedLogisticCompany.pricePerKm.toFixed(2)}}</h6>
            </v-col>
          </v-layout>
          <v-divider></v-divider>
          <v-layout wrap>
            <v-col cols="12" md="6">
              <h6>Total</h6>
            </v-col>
            <v-col cols="12" md="5">
              <h6>{{ systemSetting.systemCurrency }}{{ (total + selectedLogisticCompany.pricePerKm).toFixed(2)}}</h6>
            </v-col>
          </v-layout>

          <v-layout>
            <v-col cols="12">
              <v-progress-linear
                color="#7CBF46"
                v-show="loader2"
                :indeterminate="true"
              ></v-progress-linear>
            </v-col>
          </v-layout>

          <!-- <v-layout wrap v-if="selectedLogisticCompany.logisticsCompanyId != ''">  TODO: Replace this after Naza's fix-->
          <v-layout wrap>
            <v-col cols="12">
              <paystack
                v-if="selectedPaymentGateway == 'paystack' && selectedLogisticCompany.logisticsCompanyId !== ''"
                :amount="payoutTotalPayStack"
                :email="userDetail.email"
                :paystackkey="paystackkey"
                :reference="reference"
                :callback="paystackcallback"
                :close="close"
                :embed="false"
                currency="GHS"
              >
                <v-btn class="buttonStyle">
                  Place Order
                </v-btn>
              </paystack>
          
              <v-btn v-if="selectedPaymentGateway == 'paypal'" class="buttonStyle" @click="paymethod"
                  >Place Order
              </v-btn>

              <v-btn v-if="selectedPaymentGateway == 'e-wallet' && selectedLogisticCompany.logisticsCompanyId !== ''" class="buttonStyle" @click="eWalletPayMethod"
                  >Place Order from e-wallet
              </v-btn>
            </v-col>
          </v-layout>
          <v-layout>
            <v-progress-linear
              color="#7CBF46"
              v-show="loader"
              :indeterminate="true"
            ></v-progress-linear>
          </v-layout>
          <v-layout ref="paypal"></v-layout>
          <v-layout>
            <v-col cols="12">
              <span style="color:red;">{{logisticsErrorMessage}}</span>
            </v-col>
          </v-layout>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span style="font-size:12px;"
              >Upon clicking "Place Order". I confirm I have read and
              acknowledged all
              <router-link to="#">terms and policies</router-link>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { Country, State } from "country-state-city";
import paystack from "vue-paystack";

export default {
  name: "Checkout",
  components: {
    paystack,
  },
  data() {
    return {
      loader2: false,
      dialogPaymentMethod: false,
      dialogShippingAddress: false,
      selectedLogisticCompany:{
        logisticsCompanyId:"", ///TODO: 604a8f3e-a3fe-4cd9-a898-5e1bf203e134
        pricePerKm:0
      },
      quantity: 1,
      tab: null,
      PromoPackages: [],
      paystackkey: process.env.VUE_APP_PAYSTACKKEY, //paystack public key
      systemSetting: {
        systemCurrency: "GH₵",
      },
      shippingAddress: {
        name: "",
        phoneNumber: "",
        address: "",
        country: "",
        city: "",
        zipCode: "",
        isDefault: null,
      },
      country: [],
      states: [],
      selectedCountry: {},
      cartItems: [],
      total: 0,
      payoutTotal: 0,
      payoutTotalPayStack: 0,
      selectedPaymentGateway: null,
      errorMessage: [],
      logisticsErrorMessage: "",
      error: false,
      loader: false,
      paymentData: {
        userId: "",
        amount: "",
        cartItems: [],
      },
      userDetail: {
        name:"",
        email: "",
      },
      paymentApproval: {
        reference: "",
        userId: "",
        cartItemIds: [],
      },
      activeLogisticsCompanyData:[]
    };
  },
  created() {
    this.setAuthenticatedUser();
    this.getCountry();
    this.getLogisticsCompanies();
    this.userCartDetails();
    this.userShippingAddress();
    this.selectedPaymentGateway = localStorage.getItem(
      "selectedPaymentGateway"
    );
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  methods: {
    refresh() {
    window.location.reload();

    },
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(
          `${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId")
        )
        .then((response) => {
          this.userDetail = response.data.data;
        });
    },

    genRandonString(length) {
      var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var charLength = chars.length;
      var result = '';
      for ( var i = 0; i < length; i++ ) {
          result += chars.charAt(Math.floor(Math.random() * charLength));
      }
      return result;
    },

    // Paypal payment gateway function starts HERE //
    paymethod() {
      this.logisticsErrorMessage = "";
      if(this.selectedLogisticCompany.pricePerKm == 0)
      {
        this.logisticsErrorMessage = "Please Select a logistic company to handle your delivery"
      }
      else
      {
        const script = document.createElement("script");
        script.src =
          "https://www.paypal.com/sdk/js?client-id=AZE9y-RmpkGX4UpWi_pqiQMygHadDqmXeQ5u6noL8jXk4O-Bn_Mb7uOGy0RQUaCDe_myJX_MZGo2xNV7";
        script.addEventListener("load", this.setLoaded);
        document.body.appendChild(script);
      }
    },

    setLoaded() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.userDetail.name,
                  amount: {
                    currency_code: "NGN",
                    value: this.payoutTotal,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },

    // Paypal payment gateway function ends HERE //

    getLogisticsCompanies() {
      this.$http
        .get(`${process.env.VUE_APP_URL}logistics`)
        .then((response) => {
          response.data.data.forEach(element => {
              if(element.isActive){
                this.activeLogisticsCompanyData.push(element);
              }
          });
        });
    },

    fetchLogisticComapany(event){
        this.$http
        .get(
          `${process.env.VUE_APP_URL}logistics/` + event
        )
        .then((response) => {
          this.selectedLogisticCompany = response.data.data;
          this.payoutTotal = (response.data.data.pricePerKm + this.total);
          this.payoutTotalPayStack = (response.data.data.pricePerKm + this.total) * 100;
        });
    },

    userCartDetails() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}cart/` + localStorage.getItem("userId")
        )
        .then((response) => {
          this.cartItems = response.data.data.CartItems;
          this.total = this.cartItems.reduce(
            (total, item) => total + +item.price * +item.qty,
            0
          );

          this.payoutTotal = this.total;

          this.paymentData.userId = localStorage.getItem("userId");
          this.paymentData.amount = this.cartItems.reduce(
            (total, item) => total + +item.price * +item.qty,
            0
          );

          for (var i = 0; i < this.cartItems.length; i++) {
            this.paymentData.cartItems.push(this.cartItems[i].cartItemId);
          }
        });
    },

    userShippingAddress() {
      this.$http
        .get(
          `${process.env.VUE_APP_URL}user/shippingaddresses/` +
            localStorage.getItem("userId")
        )
        .then((response) => {
          this.shippingAddress = response.data.data;
        });
    },

    ///////Truncate a text based on the number of characters//////
    getProductContent(product) {
      let body = this.stripTags(product.description);

      return body.length > 40 ? body.substring(0, 40) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },

    selectPaymentGateway(item) {
      this.selectedPaymentGateway = null;
      localStorage.setItem("selectedPaymentGateway", item);
      this.selectedPaymentGateway = item;
      this.dialogPaymentMethod = false;
    },

    getCountry() {
      this.country = Country.getAllCountries();
    },

    getCountryStates(evt) {
      this.country.forEach((element) => {
        if (element.name == evt) {
          this.states = State.getStatesOfCountry(element.isoCode);
        }
      });
    },

    saveAddress() {
      this.loader = true;
      this.errorMessage = [];
      console.log(this.shippingAddress);
      const userId = localStorage.getItem("userId");
      let data = {
        name: this.shippingAddress.name,
        phoneNumber: this.shippingAddress.phoneNumber,
        address: this.shippingAddress.address,
        country: this.shippingAddress.country,
        city: this.shippingAddress.city,
        zipCode: this.shippingAddress.zipCode,
        isDefault: this.shippingAddress.isDefault,
      };

      this.$http
        .post(
          `${process.env.VUE_APP_URL}user/shippingaddress/` + userId,
          data
        )
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          this.loader = false;
          this.error = true;
          console.log(error.response);
          this.errorMessage = error.response.data.data;
        });
    },

    paystackcallback: function(response) {

      if (response.status == "success") {
        this.paymentApproval.reference = response.reference;
        this.paymentApproval.userId = localStorage.getItem("userId");
        for (var i = 0; i < this.cartItems.length; i++) {
          this.paymentApproval.cartItemIds.push(this.cartItems[i].cartItemId);
        }

        const data = {
          cartItemIds: this.paymentApproval.cartItemIds,
          shippingCompanyId: this.selectedLogisticCompany.logisticsCompanyId,
        }

        this.$http
        .post(
          `${process.env.VUE_APP_URL}order/` + this.paymentApproval.userId,
          data
        )
        .then(() => {
          this.$router.push('/payment/successful');
        })
        .catch((error) => {
          console.log(error.response);
        });

      }
    },

    // eWallet payment gateway function starts HERE //
    eWalletPayMethod() {
      this.loader = true;
      this.logisticsErrorMessage = "";
      if(this.selectedLogisticCompany.pricePerKm == 0)
      {
        this.logisticsErrorMessage = "Please Select a logistic company to handle your delivery";
      }
      else
      {
        // Confirm e-Wallet balance
        this.$http
        .get(`${process.env.VUE_APP_URL}wallet/` + localStorage.getItem("userId"))
        .then((response) => {
          if(this.payoutTotal > response.data.data.balance)
          {
            // Todo: Replace this with a modal 
            console.log('not enough funds in e-wallet');
          }
          else {
            console.log('Enough funds in e-wallet');
            
            const data = {
              amount: this.payoutTotal,
              description: 'Purchase from e-wallet',
            }

            
            this.$http
              .post(
                `${process.env.VUE_APP_URL}wallet/debit/` + response.data.data.walletId,
                data
              )
              .then(() => {
                    this.paymentApproval.reference = this.genRandonString(8);
                    for (var i = 0; i < this.cartItems.length; i++) {
                      this.paymentApproval.cartItemIds.push(this.cartItems[i].cartItemId);
                    }

                    const dataItem = {
                      cartItemIds: this.paymentApproval.cartItemIds,
                      shippingCompanyId: this.selectedLogisticCompany.logisticsCompanyId,
                    }

                    this.$http
                    .post(
                      `${process.env.VUE_APP_URL}order/` + localStorage.getItem("userId"),
                      dataItem
                    )
                    .then(() => {
                      this.loader = false;
                      this.$router.push('/payment/successful');
                    });
              });
          }
        });
      }
    },

    close: function() {
      console.log("Payment closed");
    },
  },
};
</script>

<style>
.productStyle {
  padding: 0px 35px 15px 35px;
}

.reProduct {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.priceStyle {
  width: 80px;
  height: auto;
  background-color: black;
  color: #ffffff;
  padding: 5px;
  border-bottom-right-radius: 5px;
}

.spanStyle {
  font-size: 14px !important;
}

.priceTag {
  color: #72bd61;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
  /* font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px; */
}

.buttonStyleGray {
  background: linear-gradient(192.88deg, #ffffff, #111212 90.69%);
  border-radius: 5px;
  color: #ffffff !important;
}

.overviewContainer {
  background-color: #f2f2f2;
  padding: 10px;
}

.whiteBg {
  background-color: #ffffff;
  padding: 20px;
}

.greyBg {
  background-color: #0f0e0e;
  padding: 20px;
}
</style>
